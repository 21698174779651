import {Customer} from '@hconnect/apiclient'
import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'
import some from 'lodash/some'
import values from 'lodash/values'

import {api} from '../../../../App.global'
import {MaterialCertificatesFilter} from '../../Filters'
import {MaterialCertificate} from '../types'

const createUniqueIdsForItems = (data: MaterialCertificate[]) => {
  return data.map((item: MaterialCertificate) => ({
    ...item,
    plantIdUnique: `${item.plantId}_${item.materialNumber}_${item.plantName}`
  }))
}
export const useMaterialCertificates = (args: MaterialCertificatesFilter, enabled: boolean) => {
  return useQuery(
    ['materialCertificates', {...args}],
    async () => {
      const {sortedByKey, sortedByDirection, ...rest} = args
      const res: AxiosResponse<MaterialCertificate[]> = await api.get('/materialCertificates', {
        params: {
          ...rest,
          sortedBy: `${sortedByKey} ${sortedByDirection}`
        }
      })

      return {
        total: res.headers['total-count'] ? +res.headers['total-count'] : 0,
        items: createUniqueIdsForItems(res.data)
      }
    },
    {
      enabled
    }
  )
}
export interface Materials {
  materialDescription: string
  materialNumber: string
}
export interface QCData {
  plantId: string
  plantName: string
  materialsProduced: Materials[]
}
export const useMaterialCertificatesForQC = ({
  limit,
  enabled,
  plantIds,
  customer
}: {
  limit: number
  enabled: boolean
  plantIds?: string[]
  customer: Customer | null
}) => {
  return useQuery(
    ['materialCertificatesQC', {limit, plantIds}],
    async () => {
      const res: AxiosResponse<MaterialCertificate[]> = await api.get('/materialCertificates', {
        params: {
          limit
        }
      })
      const plants = plantIds?.length
        ? res.data.filter((materialCertificate) => plantIds.includes(materialCertificate.plantId))
        : res.data

      const response = plants.reduce<Record<string, QCData>>((agg, curr) => {
        if (!agg[curr.plantId]) {
          agg[curr.plantId] = {
            plantId: curr.plantId,
            plantName: curr.plantName,
            materialsProduced: [
              {
                materialDescription: curr.materialDescription,
                materialNumber: curr.materialNumber
              }
            ]
          }
        } else {
          if (!some(agg[curr.plantId].materialsProduced, ['materialNumber', curr.materialNumber])) {
            // .some((materials) => materials.materialNumber === curr.materialNumber)) {
            agg[curr.plantId].materialsProduced.push({
              materialDescription: curr.materialDescription,
              materialNumber: curr.materialNumber
            })
          }
        }
        return agg
      }, {})
      return {materialCertificates: res.data, qcDataItems: values(response)}
    },
    {
      enabled: enabled || !!customer
    }
  )
}
