import {makeStyles} from '@material-ui/core'

export const useCementStyles = makeStyles(() => {
  return {
    buttonItemConatiner: {
      textTransform: 'none',
      marginLeft: '10px'
    },
    svgIcon: {
      marginRight: '5px'
    },
    buttonContainer: {
      '& button': {
        minHeight: '40px',
        width: '100%',
        padding: '14px',
        textTransform: 'none',
        fontWeight: 600,
        fontSize: '16px'
      }
    },
    downloadCertificateButton: {
      background: '#036ad4',
      '&:hover': {
        background: '#036ad4f2'
      }
    },
    generateCertificateButton: {
      background: '#fff',
      marginTop: '12px'
    },
    horizontalLine: {
      margin: '32px 0 16px 0',
      border: '1px solid #E8E8E8'
    },
    downloadRowItem: {
      color: '#016AD4',
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        marginLeft: '8px'
      }
    }
  }
})
