import {Content, Page} from '@hconnect/uikit'
import React, {useEffect, useState} from 'react'
import {Route} from 'react-router'
import {useHistory} from 'react-router-dom'

import {PermissionBoundary} from '../../Molecules/PermissionBoundary'
import {PermissionDeniedError} from '../../Molecules/PermissionDeniedError'
import {SubPageNavigation} from '../../Molecules/SubpageNavigation'
import {useFeaturesState} from '../../Organisms/Features'
import {Permission, PermissionTypes, usePermissions} from '../../Permissions'

import {Cement} from './Cement/Cement'
import Rmc from './Rmc'

import {ROUTE as CertificateRoute} from '.'
import {BusinessLineType} from '../../common/types'

interface CertificateType {
  location: any
}

export const CertificatePage: React.FC<CertificateType> = ({location}) => {
  const {getFeature} = useFeaturesState()
  const pathName = location.pathname.replace(CertificateRoute, '')
  const history = useHistory()
  const [tabValue, setTabValue] = useState(pathName)
  const {getPermissions} = usePermissions()
  const permissions = getPermissions([
    PermissionTypes.VIEW_MATERIALCERTIFICATES,
    PermissionTypes.VIEW_MATERIALTESTS
  ])
  const parsedBusinessLines = permissions.map(
    (permission: Permission) => JSON.parse(permission.dataScope).businessLine
  )
  const allowedCountriesForRmc = getFeature('RMCTestReports')
  const subPages = [
    {
      label: 'RMC',
      path: '/rmc',
      businessLine: BusinessLineType.RMC,
      component: Rmc
    },
    {
      label: 'Cement',
      path: '/cement',
      businessLine: BusinessLineType.CEM,
      component: Cement
    }
  ]
  const filterPagesByBusinessLine = (subPages, parsedBusinessLines) =>
    subPages.filter((subPage) => {
      if (
        (allowedCountriesForRmc && subPage.businessLine === BusinessLineType.RMC) ||
        subPage.businessLine === BusinessLineType.CEM
      ) {
        return parsedBusinessLines.includes(subPage.businessLine)
      }
      return undefined
    })
  const filteredSubpages = filterPagesByBusinessLine(subPages, parsedBusinessLines)

  useEffect(() => {
    if (!pathName && filteredSubpages && filteredSubpages.length > 0) {
      const defaultPath = filteredSubpages[0].path
      history.push(`${CertificateRoute}${defaultPath}`)
      setTabValue(defaultPath)
    }
  }, [pathName, history, filteredSubpages])

  return (
    <Content>
      <PermissionBoundary
        requirePermission={[
          PermissionTypes.VIEW_MATERIALCERTIFICATES,
          PermissionTypes.VIEW_MATERIALTESTS
        ]}
        renderFallback={() => <PermissionDeniedError />}
      >
        <Page data-test-id="certificate-page" boxed={false} py={0} px={0}>
          {filteredSubpages.length > 1 && (
            <SubPageNavigation
              subPages={filteredSubpages}
              baseRoute={CertificateRoute}
              tabValue={tabValue}
              setTabValue={setTabValue}
            />
          )}
          {subPages.map((page) => {
            const SubPageComponent = page.component
            return parsedBusinessLines.includes(page.businessLine) ? (
              <Route
                key={page.path.replace(/\//gm, '')}
                exact
                path={`${CertificateRoute}${page.path}`}
                render={() => <SubPageComponent />}
              />
            ) : null
          })}
        </Page>
      </PermissionBoundary>
    </Content>
  )
}
