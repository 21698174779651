import {useMutation, useQueryClient} from '@tanstack/react-query'

import {api} from '../../../../App.global'
import {MaterialCertificate} from '../types'

export const useMaterialCertificatesSubscriptions = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({certificate, subscribe}: {certificate: MaterialCertificate; subscribe: boolean}) => {
      const request = {
        materialNumber: certificate.materialNumber,
        plantName: certificate.plantName
      }

      return api({
        method: subscribe ? 'post' : 'delete',
        url: '/materialCertificates/subscriptions',
        data: request
      })
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({queryKey: ['materialCertificates']})
      }
    }
  )
}
