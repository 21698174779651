import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'

import {api} from '../../../../App.global'
import {Product} from '../../Cement/types'
import {MaterialCertificatesFilter} from '../../Filters'

export const useProducts = (filter: MaterialCertificatesFilter) => {
  return useQuery(
    ['materialCertificates/materials', {startDate: filter.startDate, endDate: filter.endDate}],
    async () => {
      const res: AxiosResponse<Product[]> = await api.get('/materialCertificates/materials', {
        params: {products: 'materials', startDate: filter.startDate, endDate: filter.endDate}
      })
      return res.data
    }
  )
}
