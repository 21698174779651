import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'

import {api} from '../../../../App.global'
import {Plant} from '../../../../Organisms/Plants/Plants.types'

export const usePlants = () => {
  return useQuery(['plants'], async () => {
    const res: AxiosResponse<Plant[]> = await api.get('/plants', {params: {plantType: 'E'}})
    return res.data
  })
}
